import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';

const pageTheme = (variantTheme) => {
  switch (variantTheme) {
    case 'orange':
      return 'orange';
    case 'blue':
      return 'blue';
    case 'green':
      return 'green';
    default:
      return '';
  }
};

const titleIcon = (variantIcon) => {
  switch (variantIcon) {
    case 'person':
      return 'icon-person';
    case 'event':
      return 'icon-event';
    case 'location':
      return 'icon-location';
    default:
      return '';
  }
};

const PageTitleAccentWidget = ({ title, icon, containerSettings, variantTheme }) => (
  <Container className={`page-title-widget page-title-accent ${pageTheme(variantTheme)}`} settings={containerSettings}>
    <div className="container">
      <h1 className="page-title no-margin-bottom">
        <span className={`page-title-icon ${titleIcon(icon)}`} />
        {title}
      </h1>
    </div>
  </Container>
);

PageTitleAccentWidget.title = 'Page Title (accent)';
PageTitleAccentWidget.preview = 'page-title.png';
PageTitleAccentWidget.category = 'Layout';

PageTitleAccentWidget.propTypes = {
  title: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
  variantTheme: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

PageTitleAccentWidget.defaults = {
  title: 'PAGE TITLE',
  containerSettings: {},
  variantTheme: 'orange',
  icon: 'location',
};

PageTitleAccentWidget.adminFields = {
  title: { type: 'text' },
  variantTheme: { type: 'select', options: [['none', 'No fill'], ['orange', 'Orange'], ['blue', 'Blue'], ['green', 'Green']] },
  icon: { type: 'select', options: [['person', 'Person'], ['event', 'Event'], ['location', 'Location']] },
};

export default PageTitleAccentWidget;
