import SectionTitleWidget from './SectionTitleWidget';
import ImageWidget from './ImageWidget';
import GalleryWidget from './GalleryWidget';
import PageTitleWidget from './PageTitleWidget';
import PageTitleAccentWidget from './PageTitleAccentWidget';
import VideoWidget from './VideoWidget';
import ArticleFeedWidget from './ArticleFeedWidget';
import HorizontalFeedWidget from './HorizontalFeedWidget';
import TextBoxWidget from './TextBoxWidget';
import CallToActionWidget from './CallToActionWidget';
import NotesWidget from './NotesWidget';

export default {
  SectionTitleWidget,
  ImageWidget,
  GalleryWidget,
  PageTitleWidget,
  VideoWidget,
  ArticleFeedWidget,
  HorizontalFeedWidget,
  TextBoxWidget,
  PageTitleAccentWidget,
  CallToActionWidget,
  NotesWidget,
};
