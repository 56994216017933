import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGb from 'date-fns/locale/en-GB';

registerLocale('en-gb', enGb);

export default ({ value, label, name }) => {
  const [dateValue, setdateValue] = useState(new Date(value));

  return (
    <div className="form-group datepicker">
      <label>{label}</label>

      <DatePicker
        locale="en-gb"
        dateFormat="dd/MM/yyyy"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        selected={dateValue}
        onChange={date => setdateValue(date)}
      />

      <input type="hidden" name={name} value={dateValue} />
    </div>
  );
};
