import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';

const CallToActionWidget = ({
  title,
  description,
  buttonLabelOne,
  buttonLinkOne,
  buttonLabelTwo,
  buttonLinkTwo,
  containerSettings,
}) => (
    <Container settings={containerSettings}>
      <div className="call-to-action flex-container">
        <div className="text-wrapper">
          <h3 className="title">{title}</h3>
          <p>{description}</p>
        </div>

        <div className="buttons-wrapper">
          <a href={buttonLinkOne} className="btn-primary">{buttonLabelOne}</a>
          {buttonLinkTwo !== '' ? <a href={buttonLinkTwo} className="btn-create">{buttonLabelTwo}</a> : ''}
        </div>
      </div>
    </Container>
  );

CallToActionWidget.title = 'Call To Action';
CallToActionWidget.category = 'General';

CallToActionWidget.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonLabelOne: PropTypes.string.isRequired,
  buttonLinkOne: PropTypes.string.isRequired,
  buttonLabelTwo: PropTypes.string.isRequired,
  buttonLinkTwo: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

CallToActionWidget.defaults = {
  title: 'Some action',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  buttonLabelOne: 'Button 1',
  buttonLinkOne: '',
  buttonLabelTwo: 'Button 2',
  buttonLinkTwo: '',
  containerSettings: {},
};

CallToActionWidget.adminFields = {
  title: { type: 'text' },
  description: { type: 'textarea' },
  buttonLabelOne: { type: 'text' },
  buttonLinkOne: { type: 'text' },
  buttonLabelTwo: { type: 'text' },
  buttonLinkTwo: { type: 'text' },
};

export default CallToActionWidget;
