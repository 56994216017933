import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';


const iconSelect = (icon) => {
  switch (icon) {
    case 'location':
      return 'icon-location';
    case 'people':
      return 'icon-profile';
    case 'events':
      return 'icon-clock';
    case 'none':
      return '';
    default:
      return '';
  }
};

const SectionTitleWidget = ({ title, icon, containerSettings }) => (
  <Container className="section-title-wrapper" settings={containerSettings}>
    <div className="flex-container">
      {icon !== '' ? <i className={iconSelect(icon)} /> : ''}
      <h3 className="section-title">{title}</h3>
    </div>
  </Container>
);

SectionTitleWidget.title = 'Section Title';
SectionTitleWidget.category = 'General';

SectionTitleWidget.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

SectionTitleWidget.defaults = {
  title: 'Headline',
  icon: '',
  containerSettings: {},
};

SectionTitleWidget.adminFields = {
  title: { type: 'text' },
  icon: { type: 'select', options: [['none', 'None'], ['location', 'Location'], ['people', 'People'], ['events', 'Events']] },
};

export default SectionTitleWidget;
