import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Img,
  EmbeddedVideo,
} from '@raketa-cms/raketa-cms';

const imageType = (image) => {
  if (typeof image === 'string') {
    return image;
  }

  return image.urls.original;
};

const FullWidthEmbeddedVideo = ({ url, play }) => <EmbeddedVideo videoUrl={url} play={play} />;

FullWidthEmbeddedVideo.propTypes = {
  url: PropTypes.string.isRequired,
};

class VideoWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPlayed: false,
    };
  }

  handleVideoPlay() {
    this.setState({ isPlayed: !this.state.isPlayed });
  }

  render() {
    const {
      containerSettings,
      variant,
      url,
      videoSummary,
      videoTitle,
      videoDescription,
      videoImage,
    } = this.props;

    return (
      <Container className={`video-widget ${variant === 'fullWidth' ? 'full-width-video' : ''}`} settings={containerSettings}>
        <div className="container">
          <EmbeddedVideo videoUrl={url} play={this.state.isPlayed} />
        </div>

        <div
          style={variant === 'fullWidth' ? { backgroundImage: `url(${imageType(videoImage)})` } : {}}
          className={`video-overlay ${this.state.isPlayed ? 'hide' : ''}`}
          onClick={() => this.handleVideoPlay()}
        >

          <div className="video-text-wrapper" style={variant === 'contained' ? { backgroundImage: `url(${imageType(videoImage)})` } : {}}>
            <div className="video-text">
              <div className="container">
                <div className="text-inner">
                  <h6 className="summary">{videoSummary}</h6>
                  <h2 className="title">{videoTitle}</h2>
                  <p className="description">{videoDescription}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

VideoWidget.title = 'Video';
VideoWidget.preview = 'text.png';
VideoWidget.category = 'Media';

VideoWidget.propTypes = {
  url: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
  videoSummary: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  videoDescription: PropTypes.string.isRequired,
  videoImage: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
};

VideoWidget.defaults = {
  url: 'https://youtu.be/nX4Y3Qv51h8',
  videoSummary: 'Video summary',
  videoTitle: 'Video title',
  videoDescription: 'Video description',
  videoImage: 'http://placehold.it/1170x568',
  containerSettings: {},
  variant: 'contained',
};

VideoWidget.adminFields = {
  url: { type: 'text' },
  videoSummary: { type: 'text' },
  videoTitle: { type: 'text' },
  videoDescription: { type: 'text' },
  videoImage: { type: 'image' },
  variant: { type: 'select', options: [['contained', 'Contained'], ['fullWidth', 'Full Width']] },
};

export default VideoWidget;
