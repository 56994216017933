import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';

const Form = ({ containerSettings }) => (
  <Container settings={containerSettings}>
    <form className="shift-one-cols form">
      <div className="col">
        <div className="form-field-wrapper disabled-field">
          <label htmlFor>fsdfs</label>
          <input disabled type="text" />
        </div>
      </div>

      <div className="col">
        <div className="form-field-wrapper">
          <label htmlFor>fsdsfsd</label>
          <input type="text" />
        </div>
      </div>

      <div className="col-full-width">
        <div className="form-field-wrapper">
          <label htmlFor>fdfd</label>
          <textarea name="" id="" cols="30" rows="10" />
        </div>
      </div>

      <div className="button-wrapper">
        <button className="btn-primary">Изпрати история</button>
      </div>

    </form>
  </Container>
);

Form.title = 'Form';
Form.category = 'Lists';

Form.propTypes = {
  containerSettings: PropTypes.object.isRequired,
};

Form.defaults = {
  containerSettings: {},
};

export default Form;
