import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Img,
  ImagePicker,
  TextInput,
  SelectMenu,
  List,
} from '@raketa-cms/raketa-cms';

class GalleryWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImage: 0,
    }
  }

  render() {
    const { list, variant, containerSettings } = this.props;

    return (
      <Container className="section-wrapper gallery" settings={containerSettings}>
        <div className="container image-preview">
          <Img src={list[this.state.currentImage].image} variant="image_widget" />
          <p className="preview-text">{list[this.state.currentImage].summary}</p>
        </div>

        <div className="gallery flex-grid-thirds">
          {list.map((item, idx) =>
            <div className="col" key={idx} onClick={() => this.setState({ currentImage: idx })}>
              <article className="gallery-item">
                <Img src={item.image} variant="article_feed" />

                <div className="text">
                  <p className="summary">{item.summary}</p>
                </div>
              </article>
            </div>)}
        </div>
      </Container>
    );
  }
}

GalleryWidget.title = 'Gallery';
GalleryWidget.category = 'Media';

GalleryWidget.defaultProps = {
  list: [],
};

GalleryWidget.propTypes = {
  list: PropTypes.array,
  variant: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

GalleryWidget.defaults = {
  list: [
    { id: 1, image: 'http://placehold.it/368x206', summary: 'But I must explain to you how all this mistaken idea of denouncing pleasure' },
    { id: 2, image: 'http://placehold.it/368x206', summary: 'But I must explain to you how all this mistaken idea of denouncing pleasure' },
    { id: 3, image: 'http://placehold.it/368x206', summary: 'But I must explain to you how all this mistaken idea of denouncing pleasure' },
  ],
  variant: '3_columns',
  containerSettings: {},
};

const ThumbField = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      onChange={value => onChangeItem('image', value)}
      value={settings.image}
    />

    <TextInput
      label="Summary"
      onChange={value => onChangeItem('summary', value)}
      value={settings.summary}
    />
  </div>
);

ThumbField.defaultProps = {
  settings: {},
};

ThumbField.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func.isRequired,
};

GalleryWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[['2_columns', '2 Columns'], ['3_columns', '3 Columns'], ['4_columns', '4 Columns']]}
      value={settings.variant}
      onChange={value => onChange('variant', value)}
    />

    <List
      listItem={(settings, onChangeItem) =>
        <ThumbField settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default GalleryWidget;
