import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Img
} from '@raketa-cms/raketa-cms';


const ImageWidget = ({ image, description, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="image-widget container">
      <figure>
        <Img src={image} variant="image_widget" />
        {description === '' ? '' : <figcaption>{description}</figcaption>}
      </figure>
    </div>
  </Container>
);

ImageWidget.title = 'Image';
ImageWidget.category = 'Media';

ImageWidget.propTypes = {
  image: PropTypes.any.isRequired,
  description: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

ImageWidget.defaults = {
  image: 'http://placehold.it/970x545',
  description: 'Image description',
  containerSettings: {},
};

ImageWidget.adminFields = {
  image: { type: 'image' },
  description: { type: 'text' },
};

export default ImageWidget;
