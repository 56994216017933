import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';


const pageTheme = (variantTheme) => {
  switch (variantTheme) {
    case 'orange':
      return 'orange';
    case 'blue':
      return 'blue';
    case 'green':
      return 'green';
    default:
      return '';
  }
};

const PageTitleWidget = ({ title, subTitle, containerSettings, variantTheme }) => (
  <Container className={`page-title-widget ${pageTheme(variantTheme)}`} settings={containerSettings}>
    <div className="container">
      <h1 className={subTitle === '' ? 'no-margin-bottom' : ''}>{title}</h1>
      {subTitle !== '' ? <h2>{subTitle}</h2> : ''}
    </div>
  </Container>
);

PageTitleWidget.title = 'Page Title';
PageTitleWidget.preview = 'page-title.png';
PageTitleWidget.category = 'Layout';

PageTitleWidget.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
  variantTheme: PropTypes.string.isRequired,
};

PageTitleWidget.defaults = {
  title: 'PAGE TITLE',
  subTitle: 'Page Description',
  containerSettings: {},
  variantTheme: 'none',
};

PageTitleWidget.adminFields = {
  title: { type: 'text' },
  subTitle: { type: 'text' },
  variantTheme: { type: 'select', options: [['none', 'No fill'], ['orange', 'Orange'], ['blue', 'Blue'], ['green', 'Green']] },
};

export default PageTitleWidget;
