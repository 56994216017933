import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  TextInput,
  List,
  RichText,
} from '@raketa-cms/raketa-cms';


const NotesWidget = ({ list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="container shift-one-cols">
      <div className="notes-widget">
        {list.map((item, idx) =>
          <dl key={idx}>
            <dt id={item.noteId}>
              <sup> [{idx + 1}] </sup>
            </dt>
            <dd dangerouslySetInnerHTML={{ __html: item.text }} />
            <a href="#" onClick={(e) => { e.preventDefault(); window.history.back(); }}>↩</a>
          </dl>)}
      </div>
    </div>
  </Container>
);

NotesWidget.title = 'Notes';
NotesWidget.category = 'Lists';

NotesWidget.defaultProps = {
  list: [],
};

NotesWidget.propTypes = {
  list: PropTypes.array,
  containerSettings: PropTypes.object.isRequired,
};

NotesWidget.defaults = {
  list: [
    { id: 1, noteId: 1, text: 'Бележка 1' },
    { id: 2, noteId: 2, text: 'Бележка 2' },
    { id: 3, noteId: 3, text: 'Бележка 3' },
  ],
  containerSettings: {},
};

const NoteField = ({ settings, onChangeItem }) => (
  <div>
    <TextInput
      label="Note ID"
      onChange={value => onChangeItem('noteId', value)}
      value={settings.noteId.toString()}
    />

    <RichText
      label="Text"
      onChange={value => onChangeItem('text', value)}
      value={settings.text}
    />
  </div>
);

NoteField.propTypes = {
  settings: PropTypes.object.isRequired,
  onChangeItem: PropTypes.func.isRequired,
};

NotesWidget.adminFields = (items, onChange) => (
  <div>
    <List
      listItem={(settings, onChangeItem) =>
        <NoteField settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default NotesWidget;
