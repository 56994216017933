import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Img,
  ImagePicker,
  TextInput,
  SelectMenu,
  List,
} from '@raketa-cms/raketa-cms';

const articleClass = (variant) => {
  switch (variant) {
    case '2_columns':
      return 'flex-grid-halfs';
    case '3_columns':
      return 'flex-grid-thirds';
    case '4_columns':
      return 'flex-grid-quarters';
    default:
      return 'flex-grid-halfs';
  }
};

const ArticleWithLink = ({ item }) => (
  <article className="card">
    <a href={item.link}>
      <Img src={item.image} variant="article_feed" />

      <div className="text">
        <h4 className="sup-title">{item.supTitle}</h4>
        <h2 className="title">{item.title}</h2>
        <h4 className="sub-title">{item.subTitle}</h4>
      </div>
    </a>
  </article>
);

ArticleWithLink.propTypes = {
  item: PropTypes.object.isRequired,
};

const ArticleWithoutLink = ({ item }) => (
  <article className="card">
    <Img src={item.image} variant="article_feed" />

    <div className="text">
      <h4 className="sup-title">{item.supTitle}</h4>
      <h2 className="title">{item.title}</h2>
      <h4 className="sub-title">{item.subTitle}</h4>
    </div>
  </article>
);

ArticleWithoutLink.propTypes = {
  item: PropTypes.object.isRequired,
};

const ArticleFeedWidget = ({ list, variant, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className={`article-feed ${articleClass(variant)}`}>
      {list.map((item, idx) =>
        <div className="col" key={idx}>
          {item.link !== '' ? <ArticleWithLink item={item} /> : <ArticleWithoutLink item={item} />}
        </div>)}
    </div>
  </Container>
);

ArticleFeedWidget.title = 'Article List (images)';
ArticleFeedWidget.preview = 'article-feed.png';
ArticleFeedWidget.category = 'Lists';

ArticleFeedWidget.defaultProps = {
  list: [],
};

ArticleFeedWidget.propTypes = {
  list: PropTypes.array,
  variant: PropTypes.string.isRequired,
  containerSettings: PropTypes.object.isRequired,
};

ArticleFeedWidget.defaults = {
  list: [
    { id: 1, image: 'http://placehold.it/368x206', link: '#', title: 'Title of article', supTitle: 'Sup Title', subTitle: 'Sub Title' },
    { id: 2, image: 'http://placehold.it/368x206', link: '#', title: 'Title of article', supTitle: 'Sup Title', subTitle: 'Sub Title' },
    { id: 3, image: 'http://placehold.it/368x206', link: '#', title: 'Title of article', supTitle: 'Sup Title', subTitle: 'Sub Title' },
  ],
  variant: '3_columns',
  containerSettings: {},
};

const ThumbField = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      onChange={value => onChangeItem('image', value)}
      value={settings.image}
    />

    <TextInput
      label="Link"
      onChange={value => onChangeItem('link', value)}
      value={settings.link}
    />

    <TextInput
      label="Sup Title"
      onChange={value => onChangeItem('supTitle', value)}
      value={settings.supTitle}
    />

    <TextInput
      label="Title"
      onChange={value => onChangeItem('title', value)}
      value={settings.title}
    />

    <TextInput
      label="Sub Title"
      onChange={value => onChangeItem('subTitle', value)}
      value={settings.subTitle}
    />
  </div>
);

ThumbField.defaultProps = {
  settings: {},
  onChangeItem: () => { },
};

ThumbField.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func,
};

ArticleFeedWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[['2_columns', '2 Columns'], ['3_columns', '3 Columns'], ['4_columns', '4 Columns']]}
      value={settings.variant}
      onChange={value => onChange('variant', value)}
    />

    <List
      listItem={(settings, onChangeItem) =>
        <ThumbField settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default ArticleFeedWidget;
