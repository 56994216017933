import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Img,
  ImagePicker,
  TextInput,
  TextArea,
  SelectMenu,
  List,
} from '@raketa-cms/raketa-cms';


const ImageWithLink = ({ item }) => (
  <a href={item.link}>
    <Img src={item.image} variant="horizontal_feed" />
  </a>
);

ImageWithLink.propTypes = {
  item: PropTypes.object.isRequired,
};

const ImageItem = ({ item }) => (
  <article className="flex-grid-four-and-eight">
    <div className="col">
      {item.link !== '' ? <ImageWithLink item={item} /> : <Img src={item.image} variant="horizontal_feed" />}
    </div>

    <div className="col">
      <div className="text-wrapper">
        <h4 className="title">{item.link !== '' ? <a href={item.link}>{item.title}</a> : item.title}</h4>
        {item.meta !== '' && <h6 className="sub-title">{item.meta}</h6>}

        <div className="desc">
          <p>{item.description}</p>
        </div>
      </div>
    </div>
  </article>
);

const TextItem = ({ item }) => (
  <article>
    <h4 className="title">{item.link !== '' ? <a href={item.link}>{item.title}</a> : item.title}</h4>
    {item.meta !== '' && <h6 className="sub-title">{item.meta}</h6>}

    <div className="desc">
      <p>{item.description}</p>
    </div>
  </article>
);

const HorizontalFeedWidget = ({ variant, list, containerSettings }) => (
  <div className="horizontal-feed">
    <Container settings={containerSettings}>
      <div className="shift-one-cols">
        {list.map((item, idx) => variant === 'text' ? <TextItem key={idx} item={item} /> : <ImageItem key={idx} item={item} />)}
      </div>
    </Container>
  </div>
);

HorizontalFeedWidget.title = 'Article List';
HorizontalFeedWidget.preview = 'horizontal-feed.png';
HorizontalFeedWidget.category = 'Lists';

HorizontalFeedWidget.defaultProps = {
  variant: 'image',
  list: [],
};

HorizontalFeedWidget.propTypes = {
  variant: PropTypes.string,
  list: PropTypes.array,
  containerSettings: PropTypes.object.isRequired,
};

HorizontalFeedWidget.defaults = {
  variant: 'image',
  list: [
    { id: 1, link: '', image: 'http://placehold.it/370x370', title: 'Article Title', meta: 'Summary, or meta info', description: 'Трябва да разбереш, нашето общество е толкова огромно, че не можем да позволим на когото и да било да дразни и вълнува отделните групи, които го съставляват. Запитай се сам какво преди всичко желаем ние в нашата страна.' },
    { id: 2, link: '', image: 'http://placehold.it/370x370', title: 'Article Title', meta: 'Summary, or meta info', description: 'Трябва да разбереш, нашето общество е толкова огромно, че не можем да позволим на когото и да било да дразни и вълнува отделните групи, които го съставляват. Запитай се сам какво преди всичко желаем ние в нашата страна.' },
    { id: 3, link: '', image: 'http://placehold.it/370x370', title: 'Article Title', meta: 'Summary, or meta info', description: 'Трябва да разбереш, нашето общество е толкова огромно, че не можем да позволим на когото и да било да дразни и вълнува отделните групи, които го съставляват. Запитай се сам какво преди всичко желаем ние в нашата страна.' },
  ],
  containerSettings: {},
};

const HorizontalFeedField = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      onChange={value => onChangeItem('image', value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={value => onChangeItem('title', value)}
      value={settings.title}
    />

    <TextInput
      label="Link"
      onChange={value => onChangeItem('link', value)}
      value={settings.link}
    />

    <TextInput
      label="Meta"
      onChange={value => onChangeItem('meta', value)}
      value={settings.meta}
    />

    <TextArea
      label="Short Description"
      onChange={value => onChangeItem('description', value)}
      value={settings.description}
    />
  </div>
);

HorizontalFeedField.defaultProps = {
  settings: {},
  onChangeItem: () => { },
};

HorizontalFeedField.propTypes = {
  settings: PropTypes.object,
  onChangeItem: PropTypes.func,
};

HorizontalFeedWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[['image', 'Show images'], ['text', 'Text only']]}
      value={settings.variant}
      onChange={value => onChange('variant', value)}
    />

    <List
      listItem={(settings, onChangeItem) =>
        <HorizontalFeedField settings={settings} onChangeItem={onChangeItem} />}
      onChangeList={onChange}
      items={items}
    />
  </div>
);

export default HorizontalFeedWidget;
