import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@raketa-cms/raketa-cms';

const Pagination = ({ containerSettings }) => (
  <Container className="container" settings={containerSettings}>
    <div className="pagination">
      <span className="disabled previous_page">Previous</span>
      <span className="current">1</span>
      <a href="/users?page=2" rel="next">2</a>
      <a href="/users?page=3">3</a>
      <a href="/users?page=2" className="next_page" rel="next">Next</a>
    </div>
  </Container>
);

Pagination.title = 'Pagination';
Pagination.category = 'Lists';

Pagination.propTypes = {
  containerSettings: PropTypes.object.isRequired,
};

Pagination.defaults = {
  containerSettings: {},
};

export default Pagination;
